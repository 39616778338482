import { useState, useEffect, useRef } from "react";
import close from "../../images/Close.svg";
import { useData } from "../../hooks/api"; 
import style from "./Modal.module.css";

function Modal() {
  console.log("Modal");
  const { data: modalData, isLoading, error } = useData("modal");
  const [img, setImg] = useState([]);
  const [active, setActive] = useState(false);
  const modalRef = useRef(null);
  const imgDesk = img.filter((r) => r.type === "desktop");
  const imgCel = img.filter((r) => r.type === "mobile");
  const urlModal = localStorage.getItem("Modal")?.toString();

  useEffect(() => {
    if (modalData) {
      setImg(modalData);
      const isAnyActive = modalData.some((i) => i.active === true && i.img !== urlModal);
      setActive(isAnyActive && !localStorage.getItem("ModalClosed"));
    }
  }, [modalData, urlModal]);

  const handleClose = () => {
    if (imgDesk.length > 0) {
      localStorage.setItem("Modal", imgDesk[0]?.img);
    }
    localStorage.setItem("ModalClosed", "true");
    setActive(false); 
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [active]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading modal data</div>;
  }

  return img.length > 0 && active ? (
    <div className={style.modalFather}>
      <div ref={modalRef} className={style.modalContainer}>
        <button onClick={handleClose} className={style.btn}>
          <img src={close} alt="X" className={style.img} />
        </button>
        <img
          className={style.imgBanner}
          src={window.innerWidth > 608 ? imgDesk[0]?.img : imgCel[0]?.img}
          alt=""
        />
      </div>
    </div>
  ) : null;
}

export default Modal;
