import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./Backoffice-NewPreparations.module.css";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Editor } from "@tinymce/tinymce-react";
import { BackofficeModal } from "../Backoffice-Modal/Backoffice-Modal";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import openModal from "../../../../actions/backoffice-actions/openModal";
import { useNavigate, useParams } from "react-router-dom";
import { Search } from "../../../../components/Search/Search";
import { filterSearchBo } from "../../../../actions";
import cleanSearch from "../../../../actions/backoffice-actions/cleanSearch";
import { ImageOutlined } from "@mui/icons-material";
import {
  useData,
  useDataById,
  useAddData,
  useEditData,
} from "../../../../hooks/api";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const BackofficeNewPreparations = () => {
  const [specialties, setSpecialties] = useState("");
  const [laboratories, setLaboratories] = useState("");
  const [filter, setFilter] = useState("");
  const [save, setSave] = useState(false);
  const [modal, setModal] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [preparation, setPreparation] = useState("");
  const [type, setType] = useState("");

  const backofficeReducer = useSelector((state) => state.backofficeReducer);
  const modalImage = backofficeReducer.setModal;
  const search = backofficeReducer.search;

  const [valueLab, setValueLab] = useState({
    name: "",
    laboratories: [],
    fast: false,
    content: "",
    date: new Date(),
  });
  const [valueSpecialty, setvalueSpecialty] = useState({
    name: "",
    specialties: search,
    content: "",
    file: [],
    date: new Date(),
  });

  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const { area, id } = useParams();

  const { data: specialtiesData } = useData("specialtiesList");
  const { data: laboratoriesData } = useData("laboratorios");
  const { data: preparationData } = useDataById(area, id);

  const addDataMutation = useAddData(area);
  const editDataMutation = useEditData(area);

  useEffect(() => {
    setSpecialties(specialtiesData?.sort() || []);
    setLaboratories(laboratoriesData?.sort() || []);
  }, [specialtiesData, laboratoriesData]);

  useEffect(() => {
    if (preparationData) {
      setPreparation({ ...preparationData, date: new Date() });
    }
  }, [preparationData]);

  useEffect(() => setDirty(false), []);

  const getByQuery = (query) => {
    if (type === "preparationsLabs" || area === "preparationsLabs") {
      const results = laboratories?.filter((lab) => lab.id === query);
      return results[0];
    } else {
      const results = specialties?.filter((specialty) => specialty.id === query);
      return results[0];
    }
  };

  const addPreparation = async () => {
    if (!id) {
      let input = type === "preparationsLabs" ? valueLab : valueSpecialty;
      addDataMutation.mutate(input, {
        onSuccess: () => {
          navigate(`/backoffice/preparaciones?type=${type}`);
        },
      });
    }
  };

  const handleChangeText = (e) => {
    if (id) {
      setPreparation({
        ...preparation,
        [e.target.name]: e.target.value,
      });
    } else {
      if (type === "preparationsLabs") {
        setValueLab({
          ...valueLab,
          [e.target.name]: e.target.value,
        });
      } else {
        setvalueSpecialty({
          ...valueSpecialty,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleFast = (e) => {
    e.preventDefault();
    if (id) {
      setPreparation({ ...preparation, fast: !preparation.fast });
    } else {
      setValueLab({ ...valueLab, fast: !valueLab.fast });
    }
  };

  const handleCleanFile = (e, name) => {
    e.preventDefault();
    if (id) {
      setPreparation({
        ...preparation,
        file: preparation.file.filter((e) => e.name !== name),
      });
    } else {
      setvalueSpecialty({
        ...valueSpecialty,
        file: valueSpecialty?.file.filter((file) => file.name !== name),
      });
    }
  };

  const handleDocuments = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const base64File = await fileToBase64(file);
    if (id) {
      setPreparation({
        ...preparation,
        file: [...preparation.file, { file: base64File, name: file.name }],
      });
    } else {
      setvalueSpecialty({
        ...valueSpecialty,
        file: [...valueSpecialty.file, { file: base64File, name: file.name }],
      });
    }
  };

  const handleMarkdown = (e) => {
    e.preventDefault();
    setModal(true);
  };

  const handleDesSelectSpecialty = (e, specialties) => {
    e.preventDefault();
    if (id) {
      setPreparation({
        ...preparation,
        specialties: preparation.specialties.filter((el) => el !== specialties),
      });
    }
    setvalueSpecialty({
      ...valueSpecialty,
      specialties: valueSpecialty.specialties.filter((el) => el !== specialties),
    });
    dispatch(filterSearchBo(specialties));
  };

  const handleSelectLabs = (e) => {
    let value = e.target.value;
    if (id) {
      if (value !== "default") {
        setPreparation({
          ...preparation,
          laboratories: [...preparation.laboratories, value],
        });
      }
    } else {
      if (value !== "default") {
        setValueLab({
          ...valueLab,
          laboratories: [...valueLab.laboratories, value],
        });
      }
    }
  };

  const handleDesSelectLabs = (e, l) => {
    e.preventDefault();
    if (l !== "default") {
      if (id) {
        setPreparation({
          ...preparation,
          laboratories: preparation.laboratories.filter((el) => el !== l),
        });
      } else {
        setValueLab({
          ...valueLab,
          laboratories: valueLab.laboratories.filter((el) => el !== l),
        });
      }
    }
  };

  const handleModal = () => {
    dispatch(openModal());
  };

  const handleClose = (e) => {
    e.preventDefault();
    setFilter("");
    setModal(false);
  };

  const handleConfirm = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      setSave(true);
      editorRef.current.setDirty(false);
      if (id) {
        setPreparation({ ...preparation, content: content });
      } else {
        if (type === "preparationsSpecialties") {
          setvalueSpecialty({ ...valueSpecialty, content: content });
        } else {
          setValueLab({ ...valueLab, content: content });
        }
      }
    }
  };

  const edit = async () => {
    editDataMutation.mutate(preparation, {
      onSuccess: () => {
        navigate(`/backoffice/preparaciones?type=${area}`);
      },
    });
  };

  const handleConfirmPreparation = () => {
    if (id) {
      edit();
    } else {
      addPreparation();
    }
    setSave(false);
  };

  const handleConfirmMarkdown = () => {
    setModal(false);
  };

  const handleSelectType = (e) => {
    setType(e.target.value);
    navigate(`/backoffice/preparaciones/crear?type=${e.target.value}`);
  };

  const open = (e) => {
    e.preventDefault();
    document.getElementById("file").click();
  };

  const adultsOrKids = (param) => {
    let filters = id
      ? preparation?.specialties?.filter(
          (e) => e.name !== "Diagnóstico por imágenes"
        )
      : valueSpecialty?.specialties?.filter(
          (e) => e.name !== "Diagnóstico por imágenes"
        );
    let adults = filters?.filter((e) => e.id === param);
    if (adults[0]?.adults === "false" && adults[0]?.kids === "false") {
      return "";
    } else {
      if (adults[0]?.adults === "true") {
        return "Adulto";
      } else if (adults[0]?.kids === "true") {
        return "Pediátrico";
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    if (id && area === "preparationsSpecialties") {
      setPreparation({
        ...preparation,
        specialties: search,
      });
    }
    if (valueSpecialty) {
      setvalueSpecialty({
        ...valueSpecialty,
        specialties: search,
      });
    }
  }, [search]);

  useEffect(() => {
    return () => {
      dispatch(cleanSearch());
    };
  }, []);

  return (
    <div className={style.container}>
      {!id ? (
        <div className={style.selectContainer}>
          <div>
            <label htmlFor="">Tipo de preparación :</label>
            <select
              className={style.selectType}
              onChange={(e) => handleSelectType(e)}
              name="type"
              id=""
            >
              <option value="default">-</option>
              <option value="preparationsLabs">Laboratorio</option>
              <option value="preparationsSpecialties">Especialidad</option>
            </select>
          </div>
        </div>
      ) : null}
      {type === "preparationsLabs" ||
      type === "preparationsSpecialties" ||
      id ? (
        <>
          <form className={style.form} action="">
            <div className={style.title}>
              <label className={style.titleLabel} htmlFor="">
                {type === "preparationsLabs" ? "Preparación" : "Estudio"}
              </label>
              <input
                className={style.titleInput}
                type="text"
                placeholder={id ? preparation && preparation?.name : "Nombre"}
                value={
                  id
                    ? preparation.name
                    : area === "preparationsLabs" || type === "preparationsLabs"
                    ? valueLab.name
                    : valueSpecialty.name
                }
                name="name"
                onChange={(e) => handleChangeText(e)}
              />
            </div>
            {area === "preparationsSpecialties" ||
            type === "preparationsSpecialties" ? (
              <div className={style.specialty}>
                <label htmlFor="" className={style.specialtyLabel}>
                  Especialidades relacionadas
                </label>
                <div className={style.searchContainer}>
                  <div>
                    <Search bf={true} />
                  </div>
                </div>
                <div>
                  <div>
                    {id
                      ? preparation.specialties &&
                        preparation.specialties?.map((r, index) => (
                          <div key={index} className={style.spanContainer}>
                            <CancelRoundedIcon
                              onClick={(e) => handleDesSelectSpecialty(e, r)}
                              className={style.cancel}
                            />
                            <span>{`${getByQuery(r)?.name} (${adultsOrKids(
                              r
                            )})`}</span>
                          </div>
                        ))
                      : valueSpecialty.specialties &&
                        valueSpecialty.specialties?.map((r, index) => (
                          <div key={index} className={style.spanContainer}>
                            <CancelRoundedIcon
                              onClick={(e) => handleDesSelectSpecialty(e, r)}
                              className={style.cancel}
                            />
                            <span>{`${getByQuery(r)?.name} (${
                              getByQuery(r).adults === "true"
                                ? "Adultos"
                                : "Pediatricos"
                            })`}</span>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            ) : null}
            {area === "preparationsLabs" || type === "preparationsLabs" ? (
              <div className={style.labs}>
                <label htmlFor="" className={style.labsLabel}>
                  Laboratorios donde se realiza
                </label>
                <div>
                  <select
                    onChange={(e) => handleSelectLabs(e)}
                    className={style.selectArea}
                    name=""
                    id=""
                  >
                    <>
                      <option value="default">-</option>
                      {laboratories &&
                        laboratories
                          ?.filter(
                            (e) =>
                              e.name !== "default" &&
                              e.name !== "Información general"
                          )
                          .map((lab, index) => (
                            <option
                              key={index}
                              name={lab.name}
                              value={`${lab.id}`}
                            >
                              {lab.name}
                            </option>
                          ))}
                    </>
                  </select>
                  <div>
                    {id
                      ? preparation.laboratories &&
                        preparation.laboratories?.map((l, index) => (
                          <div key={index} className={style.spanContainer}>
                            <CancelRoundedIcon
                              onClick={(e) => handleDesSelectLabs(e, l)}
                              className={style.cancel}
                            />
                            <span>{getByQuery(l)?.name}</span>
                          </div>
                        ))
                      : valueLab.laboratories &&
                        valueLab.laboratories?.map((l, index) => (
                          <div key={index} className={style.spanContainer}>
                            <CancelRoundedIcon
                              onClick={(e) => handleDesSelectLabs(e, l)}
                              className={style.cancel}
                            />
                            <span>{getByQuery(l)?.name}</span>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            ) : null}
            {area === "preparationsLabs" || type === "preparationsLabs" ? (
              <div className={style.ayuno}>
                <label className={style.ayunoLabel} htmlFor="">
                  Información adicional
                </label>
                <div className={style.ayunoContainerInput}>
                  <label htmlFor="" /* className={style.switch} */>
                    <button
                      className={style.fastButton}
                      onClick={(e) => handleFast(e)}
                    >
                      Ayuno
                    </button>
                  </label>
                  {id ? (
                    preparation.fast === false ? (
                      <CancelPresentationIcon
                        style={{ color: "red", width: "50px", height: "50px" }}
                      />
                    ) : (
                      <CheckBoxIcon
                        style={{ color: "teal", width: "50px", height: "50px" }}
                      />
                    )
                  ) : valueLab.fast === false ? (
                    <CancelPresentationIcon
                      style={{ color: "red", width: "50px", height: "50px" }}
                    />
                  ) : (
                    <CheckBoxIcon
                      style={{ color: "teal", width: "50px", height: "50px" }}
                    />
                  )}
                </div>
              </div>
            ) : null}
            {area === "preparationsSpecialties" ||
            type === "preparationsSpecialties" ? (
              <>
                <div className={style.indications}>
                  <label className={style.indicationsLabel} htmlFor="">
                    Documentos
                  </label>
                  <div className={style.indicationsContainerButton}>
                    <input
                      type="file"
                      onChange={handleDocuments}
                      name=""
                      id="file"
                      style={{ display: "none" }}
                    />
                    <PictureAsPdfIcon
                      className={style.file}
                      onClick={(e) => open(e)}
                    />
                  </div>
                  <div className={style.cancelContainer}>
                    {id
                      ? preparation &&
                        preparation.file?.map((file, index) => (
                          <div key={index} className={style.cancelFiles}>
                            <CancelRoundedIcon
                              onClick={(e) => handleCleanFile(e, file.name)}
                              className={style.cancel}
                            />
                            <span>{file.name}</span>
                          </div>
                        ))
                      : valueSpecialty &&
                        valueSpecialty.file?.map((file, index) => (
                          <div key={index}>
                            <CancelRoundedIcon
                              key={index}
                              onClick={(e) => handleCleanFile(e, file.name)}
                              className={style.cancel}
                            />
                            <span>{file.name}</span>
                          </div>
                        ))}
                  </div>
                </div>
              </>
            ) : null}
            <div className={style.indications}>
              <label className={style.indicationsLabel} htmlFor="">
                Descripción de indicaciones
              </label>
              <div className={style.indicationsContainerButton}>
                <button
                  onClick={(e) => {
                    handleMarkdown(e);
                  }}
                  className={style.indicationsButton}
                >
                  Indicaciones
                </button>
              </div>
            </div>
            <div className={style.sendContainer}>
              <button
                onClick={() => handleConfirmPreparation()}
                className={style.sendButton}
              >
                {id ? "Modificar" : "Crear"}
              </button>
            </div>
          </form>
          {modal ? (
            <article className={style.modal}>
              <section className={style.modalContainer}>
                <span>{filter.name}</span>
                <div>
                  <button className="open-modal" onClick={() => handleModal()}>
                    <ImageOutlined width="40" height="40" />
                  </button>
                </div>
                <div
                  className={
                    modalImage
                      ? "modalImageContainer"
                      : "modalImageContainerOff"
                  }
                >
                  <BackofficeModal />
                </div>
                <form className="form-markdown">
                  <Editor
                    apiKey="slz93nsspb367zfebawu3ptagqrlbuyxfora657iog3xugbm"
                    textareaName="description"
                    initialValue={id ? preparation?.content : valueLab?.content}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    onDirty={() => setDirty(true)}
                    init={{
                      selector: "textarea.tinymce",
                      theme: "silver",
                      height: 400,
                      plugins: [
                        "advlist autolink link image lists charmap preview hr anchor pagebreak",
                        "searchreplace wordcount visualblocks code visualchars nonbreaking",
                        "save table contextmenu directionality paste textcolor ",
                      ],
                      toolbar:
                        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media| print preview fullpage | forecolor backcolor",
                      image_advtab: true,
                    }}
                  />
                </form>
              </section>
              <div className={style.containerButtonGroup}>
                <button
                  className="markdown-button"
                  onClick={(e) => handleClose(e)}
                >
                  Cancelar
                </button>
                <button
                  className="modified-button"
                  onClick={() => handleConfirm()}
                  disabled={!dirty}
                >
                  Modificar
                </button>
                <button
                  className={save ? "saveButton" : "saveDisabled"}
                  disabled={save ? false : true}
                  onClick={() => handleConfirmMarkdown()}
                >
                  Guardar
                </button>
              </div>
            </article>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
