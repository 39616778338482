import { useState } from "react";
import "./Backoffice-ContactTel.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { useAddData, useData, useEditData } from "../../../../hooks/api";

export default function BackofficeContactTel() {
  let [input, setInput] = useState({ codArea: "", tel: "" }); //Estado para manejar el input
  let [errors, setErrors] = useState(); //Estado para manejar los mensajes de errores

  const { data } = useData("phone");
  const numberTel = data?.[data.length - 1] || {
    codArea: "",
    tel: "",
  };
  console.log(numberTel);
  const addDataMutation = useAddData("phone");
  const editDataMutation = useEditData("phone");

  //Maneja el evento de enviar el formulario
  async function handleSubmit(e) {
    e.preventDefault();
    if (!numberTel.tel) {
      addDataMutation.mutate({
        codArea: input.codArea,
        tel: input.tel,
      });
      setErrors("");
      alert("Teléfono actualizado");
    } else {
      editDataMutation.mutate({
        ...input,
        id: numberTel.id,
      });
      setErrors("");
      alert("Teléfono actualizado!");
    }

    setInput({ codArea: "", tel: "" });
  }

  //Manejador de cambios del input
  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ padding: "10px" }}>
        <h2>Teléfono de contacto</h2>
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <form onSubmit={handleSubmit}>
            <TextField
              type="tel"
              name="codArea"
              id="codArea"
              onChange={handleChange}
              value={input.codArea}
              autoFocus
              label="Código de area"
              sx={{ margin: "10px", padding: "10px" }}
              required
            />
            <TextField
              type="tel"
              name="tel"
              id="tel"
              onChange={handleChange}
              value={input.tel}
              label="Teléfono"
              sx={{ margin: "10px", padding: "10px" }}
              required
            />
            {errors && <p className="errors">{errors}</p>}
            <Button type="submit" variant="outlined" sx={{ margin: "10px" }}>
              {numberTel.tel ? "Editar" : "Crear"}
            </Button>
          </form>
        </Card>
        <hr />
        <Card raised={true} sx={{ margin: "10px", padding: "10px" }}>
          <h4>Télefono vigente: </h4>
          <a
            href={`tel:+54${numberTel.codArea}${numberTel.tel}`}
            className="llamar"
          >
            {`(${numberTel.codArea}) ${numberTel.tel}`}
          </a>
        </Card>
      </Card>
    </Box>
  );
}
