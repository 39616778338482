import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import style from "./Backoffice-Staff.module.css";
import { useData, useDeleteData } from "../../../../hooks/api";
import { Link } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import cleanSearch from "../../../../actions/backoffice-actions/cleanSearch";
import { useDispatch, useSelector } from "react-redux";

export const BackofficeStaff = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [specialty, setSpecialty] = useState([]);
  const [units, setUnits] = useState([]);
  const labs = useSelector((state) => state.rootReducer.laboratories);
  const [profesion, setProfesion] = useState([]);
  const [staff, setStaff] = useState([]);
  const [filtro, setFiltro] = useState("def");
  const [filtroName, setFiltroName] = useState("");
  const [adultsGuards, setAdultsGuards] = useState([]);
  const [kidsGuards, setKidsGuards] = useState([]);

  const { data: specialtiesData } = useData("specialtiesList");
  const { data: unitsData } = useData("unidadesAsistenciales");
  const { data: professionsData } = useData("profesion");
  const { data: staffData, isLoading, error } = useData("staff");
  const { data: adultsGuardsData } = useData("guardiaDeAdultos");
  const { data: kidsGuardsData } = useData("guardiaPediatrica");
  const deleteStaffMutation = useDeleteData("staff");

  useEffect(() => {
    setSpecialty(specialtiesData);
  }, [specialtiesData]);

  useEffect(() => {
    setUnits(unitsData);
  }, [unitsData]);

  useEffect(() => {
    setProfesion(professionsData);
  }, [professionsData]);

  useEffect(() => {
    setStaff(staffData);
  }, [staffData]);

  useEffect(() => {
    setAdultsGuards(adultsGuardsData);
  }, [adultsGuardsData]);

  useEffect(() => {
    setKidsGuards(kidsGuardsData);
  }, [kidsGuardsData]);

  const getByQuery = (query) => {
    let results = specialty?.filter((specialty) => specialty.id === query);
    if (results?.length < 1) {
      results = units?.filter((unit) => unit.id === query);
    }
    if (results?.length === 0) {
      results = labs?.filter((lab) => lab.id === query);
    }
    if (results?.length === 0) {
      results = adultsGuards?.filter((guard) => guard.id === query);
    }
    if (results?.length === 0) {
      results = kidsGuards?.filter((guard) => guard.id === query);
    }
    return results[0];
  };

  const adultsOrKids = (id) => {
    let filters = specialty?.filter(
      (e) => e.name !== "Diagnóstico por imágenes"
    );
    let adults = filters?.filter((e) => e.id === id);
    if (adults[0]?.adults === "false" && adults[0]?.kids === "false") {
      return "";
    } else {
      if (adults[0]?.adults === "true") {
        return "Adulto";
      } else if (adults[0]?.kids === "true") {
        return "Pediátrico";
      } else {
        return "";
      }
    }
  };

  const getByQueryPuesto = (query) => {
    const results = profesion?.filter((profesion) => profesion.id === query);
    return results[0]?.name;
  };

  const deletePreparation = async (id) => {
    deleteStaffMutation.mutate(id, {
      onSuccess: () => {
        setStaff((prev) => prev.filter((item) => item.id !== id));
      },
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      width: 200,
      alignHeader: "center",
    },
    {
      field: "especialidad",
      headerName: "Relacionado a :",
      width: 450,
      renderCell: (params) => {
        return (
          <div className="check">
            {params.row.especialidad?.map((e) =>
              getByQuery(e)?.adults === "false" ||
              getByQuery(e)?.adults === "true" ? (
                <span key={e}>{`${getByQuery(e)?.name} (${adultsOrKids(
                  e
                )}) - `}</span>
              ) : (
                <span key={e}>{`${getByQuery(e)?.name} - `}</span>
              )
            )}
          </div>
        );
      },
    },
    {
      field: "puesto",
      headerName: "Puesto :",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="check">
            <span>{`${getByQueryPuesto(params.row.puesto)}`}</span>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "Editar/Borrar",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="delete-edit-button">
            <Link
              style={{ textDecoration: "none" }}
              to={`/backoffice/staff/${params.row.id}`}
            >
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutlinedIcon
              className="userListDelete"
              onClick={() =>
                window.confirm("Está seguro que desea eliminar este item?")
                  ? deletePreparation(params.row.id)
                  : null
              }
            />
          </div>
        );
      },
    },
  ];

  const handleFiltro = (e) => {
    setFiltro(e.target.value);
  };

  const handleName = (e) => {
    setFiltroName(e.target.value);
  };

  useEffect(() => {
    dispatch(cleanSearch());
  }, [dispatch]);

  useEffect(() => {
    setRows(
      staff && filtro === "def" && filtroName === ""
        ? staff?.map((u) => {
            return {
              id: u.id,
              name: u.name,
              puesto: u.profession,
              especialidad: u.specialty,
            };
          })
        : filtro === "def" && filtroName !== ""
        ? staff
            ?.filter((e) =>
              e.name.toLowerCase().includes(filtroName.toLowerCase())
            )
            .map((u) => {
              return {
                id: u.id,
                name: u.name,
                puesto: u.profession,
                especialidad: u.specialty,
              };
            })
        : filtro !== "def" && filtroName === ""
        ? staff
            ?.filter((e) => e.specialty.includes(filtro))
            .map((u) => {
              return {
                id: u.id,
                name: u.name,
                puesto: u.profession,
                especialidad: u.specialty,
              };
            })
        : staff
            ?.filter((e) =>
              e.name.toLowerCase().includes(filtroName.toLowerCase())
            )
            .filter((e) => e.specialty.includes(filtro))
            .map((u) => {
              return {
                id: u.id,
                name: u.name,
                puesto: u.profession,
                especialidad: u.specialty,
              };
            })
    );
  }, [staff, filtro, filtroName]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <>
      <div className={style.container_btn}>
        <Link to="/backoffice/staff/create">
          <button className={style.btn}>Crear nuevo</button>
        </Link>
      </div>
      <div className={style.filtros}>
        <div>
          <label>Nombre: </label>
          <input
            placeholder="Nombre del profesional"
            onChange={(e) => handleName(e)}
          ></input>
        </div>
        <div>
          <label>Especialidad: </label>
          <select onChange={(e) => handleFiltro(e)}>
            <option value="def">-</option>
            <option disabled>-------Especialidades-------</option>
            {specialty.map((special) => (
              <option value={special.id} key={special.id}>
                {special.name}
              </option>
            ))}
            <option disabled>-------Laboratorios-------</option>
            {labs
              .filter((e) => e.name !== "default")
              .map((lab) => (
                <option value={lab.id} key={lab.id}>
                  {lab.name}
                </option>
              ))}
            <option disabled>-------Unidades-------</option>
            {units
              .filter(
                (e) =>
                  e.name !== "default" &&
                  e.name !== "¿Qué son las unidades asistenciales?"
              )
              .map((special) => (
                <option value={special.id} key={special.id}>
                  {special.name}
                </option>
              ))}
            <option disabled>-------Guardias de Adulto-------</option>
            {adultsGuards &&
              adultsGuards
                .filter((e) => e.name !== "default")
                .map((guard) => (
                  <option value={guard.id} key={guard.id}>
                    {guard.name}
                  </option>
                ))}
            <option disabled>-------Guardias Pediátricas-------</option>
            {kidsGuards &&
              kidsGuards
                .filter((e) => e.name !== "default")
                .map((guard) => (
                  <option value={guard.id} key={guard.id}>
                    {guard.name}
                  </option>
                ))}
          </select>
        </div>
      </div>
      <DataGrid
        rows={rows}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        checkboxSelection
      />
    </>
  );
};
