import { useState, useEffect } from "react";
import style from "./Backoffice-Preparations.module.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid } from "@mui/x-data-grid";
import BackofficeFilter from "../Backoffice-Filter/BackofficeFilter";
import { useData, useDeleteData } from "../../../../hooks/api";

export const BackofficePreparations = () => {
  const [searchParams] = useSearchParams();
  let queryParams = `${searchParams.get("type")}` || "";
  const [type, setType] = useState(queryParams);
  const [rows, setRows] = useState("");
  const navigate = useNavigate();
  const [filterSpecialty, setFilterSpecialty] = useState([]);

  const { data: laboratories } = useData("laboratorios");
  laboratories.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  const { data: preparations } = useData(type);
  preparations.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0));
  const { data: specialties } = useData("specialtiesList");
  specialties.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  const { mutate: deletePreparation } = useDeleteData(type);

  const columns = [
    {
      field: "name",
      headerName: "Preparación",
      width: 300,
      alignHeader: "center",
    },
    {
      field: "adults",
      headerName: "Relacionado a :",
      width: 500,
      renderCell: (params) => {
        return (
          <div className="check">
            {type === "preparationsLabs"
              ? params.row.laboratories?.map((e, i) => (
                  <span key={i}>{`${getByQuery(e)} -`}</span>
                ))
              : params.row.specialties?.map((e, i) => (
                  <span key={i}>
                    {getByQuery(e) === "Diagnóstico por imágenes"
                      ? `${getByQuery(e)}`
                      : `${getByQuery(e)} (${adultsOrKids(e)})`}
                  </span>
                ))}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "Editar/Borrar",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="delete-edit-button">
            <Link
              style={{ textDecoration: "none" }}
              to={`/backoffice/preparaciones/${type}/${params.row.id}`}
            >
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutlinedIcon
              className="userListDelete"
              onClick={() =>
                window.confirm("Está seguro que desea eliminar este item?")
                  ? deletePreparation(params.row.id)
                  : null
              }
            />
          </div>
        );
      },
    },
  ];

  const adultsOrKids = (id) => {
    let filters = specialties?.filter(
      (e) => e.name !== "Diagnóstico por imágenes"
    );
    let adults = filters?.filter((e) => e.id === id);
    if (adults[0]?.adults === "false" && adults[0]?.kids === "false") {
      return "";
    } else {
      if (adults[0]?.adults === "true") {
        return "Adulto";
      } else {
        return "Pediátrico";
      }
    }
  };

  const getByQuery = (queryId) => {
    if (type === "preparationsLabs") {
      const results =
        laboratories && laboratories?.filter((lab) => lab.id === queryId);
      return results[0]?.name;
    } else {
      const results =
        specialties &&
        specialties?.filter((specialty) => specialty.id === queryId);
      return results[0]?.name;
    }
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    if (value === "all") {
      setFilterSpecialty(preparations);
    } else {
      if (type === "preparationsLabs") {
        const filter =
          preparations &&
          preparations?.filter((preparation) =>
            preparation.laboratories.some((e) => e === value)
          );
        setFilterSpecialty(
          filter?.sort((a, b) =>
            a.date > b.date ? -1 : b.date > a.date ? 1 : 0
          )
        );
      } else {
        const filter =
          preparations &&
          preparations?.filter((preparation) =>
            preparation.specialties.some((e) => e === value)
          );
        setFilterSpecialty(
          filter?.sort((a, b) =>
            a.date > b.date ? -1 : b.date > a.date ? 1 : 0
          )
        );
      }
    }
  };

  const handleSelectType = (e) => {
    setType(e.target.value);
    navigate(`/backoffice/preparaciones?type=${e.target.value}`);
  };

  useEffect(() => {
    setFilterSpecialty(preparations);
  }, [preparations]);

  useEffect(() => {
    if (type === "preparationsLabs") {
      setRows(
        filterSpecialty &&
          filterSpecialty?.map((u) => {
            return {
              id: u.id,
              name: u.name,
              laboratories: u.laboratories,
              content: u.content,
            };
          })
      );
    } else {
      setRows(
        filterSpecialty &&
          filterSpecialty?.map((u) => {
            return {
              id: u.id,
              name: u.name,
              specialties: u.specialties,
              content: u.content,
            };
          })
      );
    }
  }, [filterSpecialty]);

  return (
    <div className={style.container}>
      <div className={style.selectContainer}>
        <div className={style.containerButton}>
          <div>
            <label htmlFor="">Tipo de preparación :</label>
            <select
              className={style.selectType}
              onChange={(e) => handleSelectType(e)}
              name="type"
              id=""
            >
              <option value="default">-</option>
              {queryParams === "preparationsLabs" ? (
                <>
                  <option
                    /* onClick={() => navigate('/preparaciones?type=preparationsLabs')} */ selected
                    value="preparationsLabs"
                  >
                    Laboratorio
                  </option>
                  <option
                    /* onClick={() => navigate('/preparaciones?type=preparationsSpecialties')} */ value="preparationsSpecialties"
                  >
                    Especialidad
                  </option>
                </>
              ) : queryParams === "preparationsSpecialties" ? (
                <>
                  <option
                    /* onClick={() => navigate('/preparaciones?type=preparationsLabs')} */ value="preparationsLabs"
                  >
                    Laboratorio
                  </option>
                  <option
                    /* onClick={() => navigate('/preparaciones?type=preparationsSpecialties')} */ selected
                    value="preparationsSpecialties"
                  >
                    Especialidad
                  </option>
                </>
              ) : (
                <>
                  <option
                    /* onClick={() => navigate('/preparaciones?type=preparationsLabs')} */ value="preparationsLabs"
                  >
                    Laboratorio
                  </option>
                  <option
                    /* onClick={() => navigate('/preparaciones?type=preparationsSpecialties')} */ value="preparationsSpecialties"
                  >
                    Especialidad
                  </option>
                </>
              )}
            </select>
          </div>
          <button
            onClick={() =>
              navigate("/backoffice/preparaciones/crear?type=preparationsLabs")
            }
            className={style.newButton}
          >
            Nueva
          </button>
        </div>
        {type === "preparationsLabs" ? (
          <BackofficeFilter labs={laboratories} handlerChange={handleSelect} />
        ) : type === "preparationsSpecialties" ? (
          <BackofficeFilter
            specialties={specialties}
            handlerChange={handleSelect}
          />
        ) : null}
      </div>
      <div className="datagridContainer">
        <DataGrid
          rows={rows}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          checkboxSelection
        />
      </div>
    </div>
  );
};
