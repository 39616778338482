import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import preparationsSpecialties from "../mocks/preparationsSpecialties.json";
import preparationsLabs from "../mocks/preparationsLabs.json";

import { fetchAuthSession } from "@aws-amplify/auth";

const getAuthToken = async () => {
  try {
    const session = await fetchAuthSession();
    return session?.tokens?.idToken || null;
  } catch (error) {
    console.error("Error fetching auth session:", error);
    return null;
  }
};

const API_URL = process.env.REACT_APP_API_URL;
const ASSETS_URL = process.env.REACT_APP_ASSETS_URL;
const client = axios.create({
  baseURL: API_URL,
});

client.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const isLocalhost = API_URL.includes("localhost");

const checkFileSize = (file, maxSizeMB) => {
  const fileSizeMB = file.size / (1024 * 1024);
  return fileSizeMB <= maxSizeMB;
};

//Esta preparado el hook y la api para traer de manera paginada. Hay que adaptar la respuesta para eso
//Si desactiva cache hace un fullscan en la tabla
export const useData = (
  name,
  limit,
  lastEvaluatedKey,
  includePaginationInfo = false,
  cache = true
) => {

  const queryKey = [
    name,
    limit,
    lastEvaluatedKey,
    includePaginationInfo,
    cache,
  ].filter(Boolean);

  return useQuery({
    queryKey,
    initialData: [],
    queryFn: async () => {
      if (cache) {
        try {
          const url = `https://${ASSETS_URL}/cache/${name}.json`;
          const response = await axios.get(url);
          return response.data;
        } catch (error) {
          console.warn(
            "Error al obtener datos desde JSON, llamando a la API",
            error
          );
          const params = {
            limit,
            includePaginationInfo,
          };

          if (lastEvaluatedKey !== undefined) {
            params.lastEvaluatedKey = encodeURIComponent(
              JSON.stringify(lastEvaluatedKey)
            );
          }

          const response = await client.get(`/${name}`, { params });
          return response.data.items;
        }
      } else {
        const params = {
          limit,
          includePaginationInfo,
        };

        if (lastEvaluatedKey !== undefined) {
          params.lastEvaluatedKey = encodeURIComponent(
            JSON.stringify(lastEvaluatedKey)
          );
        }

        const response = await client.get(`/${name}`, { params });
        return response.data.items;
      }
    },
    keepPreviousData: true,
  });
};

export const useDataById = (name, id) => {
  return useQuery({
    queryKey: [name, id],
    initialData: id ? [] : {},
    queryFn: async () => {
      if (!id) {
        return {};
      }
      const response = await client.get(`/${name}/${id}`);
      return response.data;
    },
    enabled: !!id,
  });
};

export const useAddData = (area) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newData) => {
      const response = await client.post(`/${area}`, newData);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([area]);
      return data.id;
    },
  });
};

export const useEditData = (area) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (editArea) => {
      console.log(area);
      const response = await client.put(`/${area}/${editArea.id}`, editArea);
      return response.data;
    },
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(["sections", variables.id]);
    },
  });
};

export const useEditMultipleData = (area) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (editItems) => {
      const response = await client.put(`/${area}/bulk-update`, {
        items: editItems,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["sections"]);
    },
  });
};

export const useDeleteData = (area) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => {
      const response = await client.delete(`/${area}/${id}`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [area] });
    },
  });
};

export const useNewsById = (id) => {
  return useQuery({
    queryKey: ["news", id],
    queryFn: async () => {
      if (!id) return null;
      const response = await client.get(`/news/${id}`);
      return response.data;
    },
    enabled: !!id,
  });
};

export const useCreateOrUpdateEquipment = () => {
  return useMutation({
    mutationFn: async (equipment) => {
      const response = await client.post("/equipment", equipment);
      return response.data;
    },
  });
};

export const useCarousel = ({ mobile = window.innerWidth < 608 } = {}) => {
  return useQuery({
    queryKey: [mobile ? "carouselMobile" : "carousel"],
    queryFn: async () => {
      const response = await client.get("/carousel", {
        params: { mobile: mobile.toString() },
      });
      return response.data;
    },
  });
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const useAddCarouselImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ image, mobile }) => {
      if (!checkFileSize(image, 5)) {
        throw new Error("File size exceeds 5 MB");
      }
      const base64Image = await fileToBase64(image);
      const response = await client.post("/carousel", {
        image: base64Image,
        imageName: image.name,
        mobile: mobile ? "true" : "false", // Envía el campo mobile como cadena
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["carousel"]);
    },
  });
};

export const useUploadImageToS3 = () => {
  return useMutation({
    mutationFn: async (file) => {
      const base64Image = await fileToBase64(file);
      const response = await client.post("/uploadImage", {
        image: base64Image,
        imageName: file.name,
      });
      return response.data;
    },
  });
};

export const useConfirmEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (obj) => {
      const response = await client.put(`/trabajaConNosotros`, {
        id: obj.elem.id,
        email: obj.value,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["trabajaConNosotros"]);
    },
  });
};

export const useDeleteCarouselImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, mobile }) => {
      const response = await client.delete(`/carousel/${id}`, {
        params: { id, mobile },
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["carousel"]);
    },
  });
};

export const useUpdateCarouselImageUrl = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, mobile, url }) => {
      const response = await client.put(`/carousel/${id}`, { mobile, url });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["carousel"]);
    },
  });
};
export const useDeleteAllCarouselImages = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (images) => {
      const response = await client.delete("/carousel", {
        data: images,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["carousel"]);
    },
  });
};

export const useUpdateEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ currentEmail, newEmail }) => {
      const response = await client.put("/email", {
        email: currentEmail,
        newEmail: newEmail,
      });
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["email"], (oldData) => ({
        ...oldData,
        ...data,
      }));
    },
  });
};
export const createEmail = async (newEmail) => {
  const response = await client.post("/email", {
    email: newEmail,
  });
  return response.data;
};

export const useEmail = () => {
  return useQuery({
    queryKey: ['email'],
    queryFn: async () => {
      const response = await client.get("/email");
      return response.data;
    },
  });
};

export const useAddInstallationImage = () => {
  return useMutation({
    mutationFn: async (formData) => {
      const file = formData.get("file");
      if (!checkFileSize(file, 5)) {
        throw new Error("File size exceeds 5 MB");
      }
      const response = await client.post("/galeriaInstalaciones", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    },
  });
};

export const useDeleteInstallationImage = () => {
  return useMutation({
    mutationFn: async ({ id }) => {
      const response = await client.delete(`/galeriaInstalaciones/${id}`);
      return response.data;
    },
  });
};

export const useDeleteInstallation = () => {
  return useMutation({
    mutationFn: async (id) => {
      const response = await client.delete(`/installations/${id}`);
      return response.data;
    },
  });
};

export const useDeleteEquipment = () => {
  return useMutation({
    mutationFn: async (id) => {
      if (isLocalhost) {
        return id;
      }
      const response = await client.delete(`/equipment/${id}`);
      return response.data;
    },
  });
};

export const useAddEquipmentImage = () => {
  return useMutation({
    mutationFn: async (formData) => {
      const file = formData.get("file");
      if (!checkFileSize(file, 5)) {
        throw new Error("File size exceeds 5 MB");
      }
      const response = await client.post("/galeriaEquipamientos", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    },
  });
};

export const useDeleteEquipmentImage = () => {
  return useMutation({
    mutationFn: async ({ id }) => {
      const response = await client.delete(`/galeriaEquipamientos/${id}`);
      return response.data;
    },
  });
};

export const useGetGuiaPaciente = (guia) => {
  return useQuery({
    queryKey: ["guiaPaciente", guia],
    queryFn: async () => {
      const response = await client.get(`/guiaPaciente?name=${guia}`);
      return response.data;
    },
    enabled: !!guia,
  });
};

export const useGetNews = () => {
  return useQuery({
    queryKey: ["news"],
    queryFn: async () => {
      const response = await client.get("/news");
      return response.data.filter((e) => e.active === true);
    },
  });
};

export const useIndYPrep = (name) => {
  return useQuery({
    queryKey: ["indYPrep"],
    initialData: [],
    queryFn: async () => {
      if (isLocalhost) {
        if (name === "Indicaciones y preparaciones") {
          return preparationsLabs;
        } else {
          return preparationsSpecialties;
        }
      }
      const response = await client.get(`/indYPrep/${name}`);
      return response.data;
    },
  });
};

export const useAddProfession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newProfession) => {
      const response = await client.post("/profesion", {
        name: newProfession,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["profesion"]);
    },
  });
};

export const useGetStaffById = (id) => {
  return useQuery({
    queryKey: ["staff", id],
    queryFn: async () => {
      const response = await client.get(`/staff/${id}`);
      return response.data;
    },
    enabled: !!id,
  });
};

export const useAddOrUpdateStaff = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (staff) => {
      if (staff.id) {
        const response = await client.put(`/staff/${staff.id}`, staff);
        return response.data;
      } else {
        const response = await client.post("/staff", staff);
        return response.data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["staff"]);
    },
  });
};
