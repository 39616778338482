import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./Backoffice-NewsMaker.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { useData, useDataById, useAddData, useEditData } from "../../../../hooks/api";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Editor } from "@tinymce/tinymce-react";
import { BackofficeModal } from "../Backoffice-Modal/Backoffice-Modal";
import openModal from "../../../../actions/backoffice-actions/openModal";
import { ImageOutlined } from "@mui/icons-material";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const BackofficeNewsMaker = () => {
  const [specialties, setSpecialties] = useState([]);
  const [labs, setLabs] = useState([]);
  const [units, setUnits] = useState([]);
  const [staff, setStaff] = useState([]);
  const [save, setSave] = useState(false);
  const [modal, setModal] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [input, setInput] = useState({
    title: "",
    resume: "",
    img: "",
    area: [],
    specialty: [],
    date: "",
    featured: "false",
    content: "",
    active: false,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: newsEdit, isLoading, error } = useDataById("news", id);
  const { data: specialtiesData } = useData("specialties");
  const { data: labsData } = useData("laboratorios");
  const { data: unitsData } = useData("unidadesAsistenciales");
  const { data: staffData } = useData("staff");

  const addDataMutation = useAddData("news");
  const editDataMutation = useEditData("news");

  const backofficeReducer = useSelector((state) => state.backofficeReducer);
  const modalImage = backofficeReducer.setModal;

  useEffect(() => {
    if (newsEdit && id) {
      setInput(newsEdit);
      if (newsEdit.img) {
        setPreviewUrl(newsEdit.img);
      }
    }
  }, [newsEdit, id]);

  useEffect(() => {
    setSpecialties(specialtiesData || []);
    setLabs(labsData || []);
    setUnits(unitsData || []);
    setStaff(staffData || []);
  }, [specialtiesData, labsData, unitsData, staffData]);

  const handleChangeText = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value || "",
    });
  };

  const handleSelect = (e) => {
    if (e.target.value !== "default") {
      setInput({
        ...input,
        area: [...input.area, e.target.value],
      });
    }
  };

  const handleIcons = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = { ...input };

    if (selectedFile) {
      const base64File = await fileToBase64(selectedFile);
      formData = {
        ...formData,
        fileContent: base64File,
        fileName: selectedFile.name,
      };
    }

    if (id) {
      editDataMutation.mutate(formData, {
        onSuccess: () => {
          navigate("/backoffice/noticias");
        },
      });
    } else {
      addDataMutation.mutate(formData, {
        onSuccess: () => {
          navigate("/backoffice/noticias");
        },
      });
    }
  };

  const deleteIcon = (e) => {
    e.preventDefault();
    setInput({
      ...input,
      img: "",
    });
    setPreviewUrl(null);
    setSelectedFile(null);
  };

  const open = (e) => {
    e.preventDefault();
    document.getElementById("file").click();
  };

  const handleSelectSpecialty = (e) => {
    const results = [...specialties, ...labs, ...units, ...staff].filter((f) => f.id === e.target.value);
    if (e.target.value !== "default" && results.length > 0) {
      setInput({
        ...input,
        specialty: [
          ...input.specialty,
          { name: results[0]?.name, id: results[0]?.id },
        ],
      });
    }
  };

  const handleDesSelect = (e) => {
    setInput({
      ...input,
      area: input.area.filter((el) => el !== e),
    });
  };

  const handleDesSelectSpecialty = (specialty) => {
    setInput({
      ...input,
      specialty: input.specialty.filter((el) => el.id !== specialty.id),
    });
  };

  const handleFeatured = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleActive = (e) => {
    e.preventDefault();
    setInput({
      ...input,
      active: !input.active,
    });
  };

  const handleDate = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleModal = () => {
    dispatch(openModal());
  };

  const handleConfirm = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      setSave(true);
      editorRef.current.setDirty(false);
      setInput({ ...input, content: content });
    }
  };

  const getByQuery = (query) => {
    const result = [...specialties, ...labs, ...units, ...staff].find(item => item.id === query);
    return result || {};
  };

  if (id && isLoading) {
    return <div>Cargando...</div>;
  }

  if (id && error) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <div className={style.container}>
      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.title}>
          <label className={style.titleLabel} htmlFor="title">Título</label>
          <input
            className={style.titleInput}
            type="text"
            required
            placeholder="Título"
            value={input.title || ""}
            name="title"
            onChange={handleChangeText}
          />
        </div>
        <div className={style.resume}>
          <label className={style.resumeLabel} htmlFor="resume">Resumen</label>
          <textarea
            className={style.textAreaResume}
            type="text"
            name="resume"
            onChange={handleChangeText}
            value={input.resume || ""}
          />
        </div>
        <div className={style.image}>
          <label className={style.imageLabel} htmlFor="file">Imagen</label>
          <div className={style.imageContainer}>
            {!previewUrl && (
              <AddPhotoAlternateIcon className={style.iconElement} onClick={open} />
            )}
            <input
              className={style.iconInput}
              type="file"
              name="file"
              id="file"
              onChange={handleIcons}
              style={{ display: "none" }}
            />
            {previewUrl && (
              <div className={style.cancel}>
                <CancelRoundedIcon className={style.x} onClick={deleteIcon} />
                <img className={style.img} src={previewUrl} alt="img" />
              </div>
            )}
          </div>
        </div>
        <div className={style.image}>
          <label className={style.imageLabel} htmlFor="date">Fecha</label>
          <div className={style.imageContainer}>
            <input
              onChange={handleDate}
              className={style.inputDate}
              type="date"
              name="date"
              value={input.date || ""}
            />
          </div>
        </div>
        <div className={style.area}>
          <label htmlFor="area" className={style.areaLabel}>Área</label>
          <div>
            <select onChange={handleSelect} className={style.selectArea} name="area" id="area">
              <option value="default">-</option>
              <option value="Programa de buena salud">Programa de Buena Salud</option>
              <option value="Actividades para la comunidad">Actividades para la comunidad</option>
              <option value="Novedades institucionales">Noticias institucionales</option>
              <option value="Actividades académicas">Actividades académicas</option>
            </select>
            <div>
              {input.area && input.area.map((e, index) => (
                <div key={index} className={style.spanContainer}>
                  <CancelRoundedIcon onClick={() => handleDesSelect(e)} className={style.cancel} />
                  <span>{e}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={style.specialty}>
          <label htmlFor="specialty" className={style.areaLabel}>Relacionado a la especialidad...</label>
          <div>
            <select onChange={handleSelectSpecialty} className={style.selectArea} name="specialty" id="specialty">
              <option value="default" disabled>ESPECIALIDADES</option>
              {specialties.map((specialty, index) => (
                <option value={specialty.id} key={index}>
                  {`${specialty.name} (${specialty.adults === "true" ? "Adulto" : "Pediátrico"})`}
                </option>
              ))}
              <option value="default" disabled>LABORATORIOS</option>
              {labs.map((lab, index) => (
                <option value={lab.id} key={index}>{lab.name}</option>
              ))}
              <option value="default" disabled>UNIDADES ASISTENCIALES</option>
              {units.map((unit, index) => (
                <option value={unit.id} key={index}>{unit.name}</option>
              ))}
              <option value="default" disabled>STAFF</option>
              {staff.map((member, index) => (
                <option value={member.id} key={index}>
                  {`${member.name} - ${member.specialty.map((id) => getByQuery(id)?.name).join(", ")}`}
                </option>
              ))}
            </select>
            <div>
              {input.specialty && input.specialty.map((e, index) => (
                <div key={index} className={style.spanContainer}>
                  <CancelRoundedIcon onClick={() => handleDesSelectSpecialty(e)} className={style.cancel} />
                  <span>{`${e.name} (${getByQuery(e.id)?.adults === "true" ? "Adulto" : "Pediátrico"})`}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={style.featured}>
          <label className={style.featuredLabel} htmlFor="featured">Tipo de noticia</label>
          <div>
            <input
              type="checkbox"
              name="featured"
              value={input.featured === "false" ? true : false}
              onChange={handleFeatured}
              checked={input.featured === "true"}
            />
            <label className={style.featuredInputLabel} htmlFor="featured">Noticia destacada</label>
          </div>
        </div>
        <div className={style.featured}>
          <label className={style.featuredLabel} htmlFor="active">Activa</label>
          <div className={style.containerActive}>
            <label htmlFor="active">
              {input.active ? "La noticia se encuentra activa, ¿desea desactivarla?" : "La noticia está desactivada, ¿desea activarla?"}
            </label>
            <button
              className={input.active ? style.active : style.activeOff}
              onClick={handleActive}
            >
              {input.active ? "Desactivar" : "Activar"}
            </button>
          </div>
        </div>
        <div className={style.news}>
          <label className={style.newsLabel} htmlFor="content">Contenido de la noticia</label>
          <button onClick={(e) => { e.preventDefault(); setModal(true); }} className={style.buttonContent}>
            {input.content ? "Modificar" : "Crear"}
          </button>
        </div>
        <div className={style.finish}>
          <button type="submit" className={style.buttonFinish}>Finalizar</button>
        </div>
      </form>
      {modal && (
        <article className={style.modal}>
          <section className={style.modalContainer}>
            <div className={style.modalButtonContainer}>
              <button className={style.openModal} onClick={handleModal}>
                <ImageOutlined width="40" height="40" />
              </button>
            </div>
            <div className={modalImage ? style.modalImageContainer : style.modalImageContainerOff}>
              <BackofficeModal />
            </div>
            <form className={style.formMarkdown}>
              <Editor
                apiKey="slz93nsspb367zfebawu3ptagqrlbuyxfora657iog3xugbm"
                textareaName="description"
                initialValue={input.content || ""}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onDirty={() => setDirty(true)}
                init={{
                  selector: "textarea.tinymce",
                  theme: "silver",
                  height: 500,
                  plugins: [
                    "advlist autolink link image lists charmap preview hr anchor pagebreak",
                    "searchreplace wordcount visualblocks code visualchars nonbreaking",
                    "save table contextmenu directionality paste textcolor ",
                  ],
                  toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media| print preview fullpage | forecolor backcolor",
                  image_advtab: true,
                  file_picker_callback: (callback, value, meta) => {
                    if (meta.filetype === "image") {
                      document.getElementById("file").click();
                      document.getElementById("file").onchange = function () {
                        const file = this.files[0];
                        const reader = new FileReader();
                        reader.onload = function (e) {
                          callback(e.target.result, { alt: file.name });
                        };
                        reader.readAsDataURL(file);
                      };
                    }
                  }
                }}
              />
            </form>
          </section>
          <div className={style.containerButtonGroup}>
            <button className="markdown-button" onClick={(e) => { e.preventDefault(); setModal(false); }}>Cancelar</button>
            <button className="modified-button" onClick={handleConfirm} disabled={!dirty}>Modificar</button>
            <button className={save ? "saveButton" : "saveDisabled"} disabled={!save} onClick={(e) => { e.preventDefault(); setModal(false); }}>Guardar</button>
          </div>
        </article>
      )}
    </div>
  );
};
