import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import style from "./ContentRight.module.css";
import { sectionShow } from "../../actions";
import menu from "./sections.svg";
import GaleriaNews from "../GaleriaNews/GaleriaNews";
import FormularioCircuitosMedicos from "../FormularioCircuitosMedicos/FormularioCircuitosMedicos";
import ContentSug from "../ContentSug/ContentSug";

export const ContentRight = ({
  path,
  titleName,
  docShow,
  currentSpecialty,
  sugerencias,
}) => {
  const rootReducer = useSelector((state) => state.rootReducer);
  const { content, news } = rootReducer;
  const params = useParams();
  const sectionPath = params.section;
  const dispatch = useDispatch();
  let [newsGallery, setNewsGallery] = useState();

  const title =
    path === "general" ? titleName?.filter((e) => e.tag === sectionPath) : "";

  function createMarkup() {
    return { __html: content };
  }

  window.addEventListener("beforeunload", () => {
    return "¿Cancelar todo?";
  });

  const getNews = async () => {
    try {
      let newsFiltered = news && news?.filter(
        (n) => n.specialty.some(r => Object.values(r).includes(params.id))
      );
      setNewsGallery(newsFiltered);
    } catch (e) {
      console.error("No hay resultados disponibles:", e);
    }
  };

  const filterSugerencias = () => {
    let aux = sugerencias.filter((obj) => obj.name === title[0]?.name);
    return aux[0];
  };
  let filteredSugerencias = sugerencias && filterSugerencias();
  useEffect(() => {
    createMarkup();
  }, []);

  useEffect(() => {
    getNews();
  }, [currentSpecialty]);

  const openSections = () => {
    dispatch(sectionShow(true));
  };

  const getContentRrhh = () => {
    return { __html: docShow.content };
  };
  return (
    <div className={style.sectionContainer}>
      <div className={style.content}>
        <div className={style.cont}>
          <div
            className={
              params.name === "circuitosMedicos"
                ? `${style.container} ${style.reverse}`
                : style.container
            }
          >
            {params.name === "circuitosMedicos" ? null : (
              <span className={style.title}>
                {path === "general" ? title[0]?.name : sectionPath}

                {docShow && docShow.name}
              </span>
            )}
            <img
              src={menu}
              onClick={openSections}
              className={
                params.name === "circuitosMedicos"
                  ? style.imgCircuitos
                  : style.img
              }
              alt="menu"
            />
          </div>
          {params.name === "circuitosMedicos" ? null : (
            <div className={style.division}></div>
          )}
        </div>
        {docShow ? (
          <div className={style.container__busqueda_content}>
            <div
              className={style.p}
              dangerouslySetInnerHTML={getContentRrhh()}
            />
          </div>
        ) : (
          <div className={style.p} dangerouslySetInnerHTML={createMarkup()} />
        )}
        {docShow && docShow?.name !== "Información de RRHH" ? (
          <div className={style.container__enviaCV}>
            <h3>Enviá tu CV</h3>
            <span>
              Adjuntá tu currículum haciendo clic en el siguiente enlace:
            </span>
            <a
              href={`mailto:${docShow?.email}?subject=Postulación%20${docShow?.name}`}
            >
              {docShow?.email}
            </a>
          </div>
        ) : null}

        {newsGallery && newsGallery.length !== 0 && (
          <>
            {sectionPath === "Sobre el servicio" && (
              <GaleriaNews news={newsGallery && newsGallery} />
            )}
          </>
        )}

        {path === "general" &&
        (sectionPath === "Ginecologico" ||
          sectionPath === "Integral" ||
          sectionPath === "Covid" ||
          sectionPath === "Prequirurgico") ? (
          <FormularioCircuitosMedicos circuito={sectionPath} />
        ) : null}

        {sugerencias ? <ContentSug data={filteredSugerencias} /> : null}
      </div>
    </div>
  );
};
