import { useState } from "react";
import search from "./search-line.svg";
import sections from "./sections.svg";
import { useDispatch, useSelector } from "react-redux";
import { sectionShow } from "../../actions";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import style from "./ContenidoCentral.module.css";
import { useEffect } from "react";

const filtros = [
  { vista: "A-B", value: "0" },
  { vista: "C-D", value: "1" },
  { vista: "E-F", value: "2" },
  { vista: "G-I", value: "3" },
  { vista: "J-L", value: "4" },
  { vista: "M-Ñ", value: "5" },
  { vista: "O-Q", value: "6" },
  { vista: "R-S", value: "7" },
  { vista: "T-V", value: "8" },
  { vista: "W-Z", value: "9" },
];

const ContenidoCentral = () => {
  let { area } = useParams();
  let aux =
    area === "especialidades"
      ? "specialities"
      : area === "laboratorios"
      ? "laboratories"
      : "units";

  // const rootReducer = useSelector((state) => state.rootReducer);
  // const { aux } = rootReducer;
  let [active, setActive] = useState(filtros[0].vista);
  let [page, setPage] = useState("0");
  let areaSearch = useSelector((state) =>
    area === "especialidades"
      ? state.rootReducer.specialities
      : area === "laboratorios"
      ? state.rootReducer.laboratories
      : state.rootReducer.units
  );
  let category = useSelector((state) => state.rootReducer.category);
  let [foco, setFoco] = useState(false);
  let [busqueda, setBusqueda] = useState("");
  let [specialSearch, setSpecialSearch] = useState("");
  let dispatch = useDispatch();
  let [infoUA, setInfoUA] = useState("");
  const [searchParams] = useSearchParams();
  let paramName = `${searchParams.get("name")}`;

  let filterSpecials =
    aux === "specialities"
      ? category === "Adultos"
        ? areaSearch.filter((e) => e.adults === "true")
        : areaSearch.filter((e) => e.kids === "true")
      : areaSearch;
  //Funcion para menejar el hover de los filtros
  const handleClick = (e) => {
    let filter = e.target.getAttribute("filter");
    let number = e.target.getAttribute("number");
    setActive(filter);
    setPage(number);
  };

  const convertToQuery = (id) => {
    let result = areaSearch && areaSearch?.filter((unit) => unit.id === id);
    return result && result[0]?.sections[0]?.name;
  };

  //Funcion para abrir la ventana desplegable de secciones
  const openSections = () => {
    dispatch(sectionShow(true));
  };

  //Funciones para el buscador interno de secciones
  const handleFocus = () => {
    setFoco(true);
  };
  const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleBlur = async () => {
    await waitFor(200);
    setFoco(false);
  };
  const handleOption = (e) => {
    setBusqueda(e.target.innerHTML);
  };
  const filter = (r) => {
    let resultado;
    let aux = [];
    areaSearch.filter((e) => {
      if (e.name.toLowerCase().startsWith(r.toLowerCase())) {
        aux.unshift(e);
      } else if (e.name.toLowerCase().includes(r.toLowerCase())) {
        aux.push(e);
      }
    });
    if (area === "unidadesAsistenciales") {
      resultado = aux;
    } else if (category === "Adultos") {
      resultado = aux.filter((e) => e.adults === "true");
    } else {
      resultado = aux.filter((e) => e.adults === "false");
    }
    setSpecialSearch(resultado);
  };
  const handleChange = (e) => {
    setBusqueda(e.target.value);
    filter(e.target.value);
  };
  const filterInfo = (paramName) => {
    setInfoUA(areaSearch.filter((e) => e.name === paramName));
  };
  function createMarkup(r) {
    return { __html: r };
  }

  useEffect(() => {
    setSpecialSearch(areaSearch);
  }, [areaSearch]);

  useEffect(() => {
    if (area === "unidadesAsistenciales") {
      if (paramName !== undefined) {
        filterInfo(paramName);
      }
    }
    return () => {
      setInfoUA("");
    };
  }, [category]);

  return (
    <div className={style.contenidocentral__container_main}>
      <div className={style.contenidocentral__container_content}>
        <h3 className={style.contenidocentral__seccion}>
          {aux === "units" ? "" : category}
        </h3>
        {/* <div className={style.division}></div> */}
        <img
          src={sections}
          alt="Section"
          className={style.sections__button}
          onClick={openSections}
        />
        {/* <div className={style.division}></div> */}
        {aux === "units" &&
        paramName === "¿Qué son las unidades asistenciales?" ? (
          <div>
            <Helmet>
              <title>CURF - Mi Portal</title>
              <meta
                name="description"
                content="Conocé nuestras propuestas de atención interdisciplinaria personalizada de calidad"
              />
            </Helmet>
            <div
              className={style.contentMarkdownContent}
              dangerouslySetInnerHTML={createMarkup(infoUA[0]?.content)}
            />
          </div>
        ) : (
          <>
            <div className={style.contenidocentral__container_searchbar}>
              <input
                value={busqueda}
                placeholder="¿Qué estás buscando?"
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
                onChange={(e) => handleChange(e)}
                className={style.contenidocentral__searchbar}
              />
              <img
                src={search}
                alt="Search"
                className={style.contenidocentral__search_icon}
              />
              <div
                className={
                  foco
                    ? `${style.recomendaciones}`
                    : `${style.recomendaciones} ${style.false}`
                }
              >
                {specialSearch.length ? (
                  specialSearch
                    .filter((e) => e.name !== "default")
                    .filter(
                      (e) => e.name !== "¿Qué son las unidades asistenciales?"
                    )
                    .filter((u) =>
                      category === "Adultos"
                        ? u.adults === "true"
                        : u.kids === "true"
                    )
                    .map((r) => (
                      <div key={r.name}>
                        <Link
                          to={
                            r.principal
                              ? `/${area}/${r.name}/${convertToQuery(r?.id)}`
                              : r.adults === "true"
                              ? `/${area}/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                              : `/${area}/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                          }
                          className="link"
                        >
                          <p
                            key={r.id}
                            className={style.option}
                            onClick={(e) => handleOption(e)}
                          >
                            {area === "unidadesAsistenciales"
                              ? `${r.name}`
                              : r.adults === "true"
                              ? `${r.name} (adultos)`
                              : `${r.name} (pediátrico)`}
                          </p>
                        </Link>
                      </div>
                    ))
                ) : (
                  <p className="option">No encontrado</p>
                )}
              </div>
            </div>
            <div className={style.contenidocentral__container_filtrado}>
              <div
                className={`${style.contenidocentral__separacion_filtrado} ${style.no_margin}`}
              ></div>
              {filtros.map((filtro, index) => {
                return (
                  <div
                    className={style.contenidocentral__container_filtros_line}
                    key={index}
                  >
                    <span
                      filter={filtro.vista}
                      onClick={handleClick}
                      // eslint-disable-next-line react/no-unknown-property
                      number={filtro.value}
                      className={
                        active === filtro.vista
                          ? style.contenidocentral__filtrado_active
                          : style.contenidocentral__filtrado
                      }
                      key={index + 1}
                    >
                      {filtro.vista}
                    </span>
                    <div
                      className={style.contenidocentral__separacion_filtrado}
                      key={index + 2}
                    ></div>
                  </div>
                );
              })}
            </div>
            <div className={style.contenidocentral__container_content_filtrado}>
              {(() => {
                switch (page) {
                  case "0":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order1}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            A
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("A")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "A" && (
                                      <Link
                                        key={r.name}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order2}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            B
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("B")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "B" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                  case "1":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order3}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            C
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("C")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "C" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order4}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            D
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("D")
                            ) ? (
                              filterSpecials
                                .filter(
                                  (e) => e.name !== "Diagnóstico por imágenes"
                                )
                                .map(
                                  (r) =>
                                    r.name[0] === "D" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                  case "2":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order5}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            E
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("E")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "E" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order6}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            F
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("F")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "F" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                  case "3":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.order7}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            G
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("G")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "G" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order8}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            H
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("H")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "H" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order9}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            I
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("I")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "I" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                  case "4":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order10}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            J
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("J")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "J" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order11}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            K
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("K")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "K" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order12}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            L
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("L")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "L" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                  case "5":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order13}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            M
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("M")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "M" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order14}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            N
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("N")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "N" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order15}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            Ñ
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("Ñ")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "Ñ" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                  case "6":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order16}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            O
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("O")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "O" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.order17}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            P
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("P")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "P" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order18}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            Q
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("Q")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "Q" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                  case "7":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order19}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            R
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("R")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "R" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order20}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            S
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("S")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "S" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                  case "8":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order21}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            T
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("T")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "T" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order22}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            U
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("U")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "U" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order23}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            V
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("V")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "V" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                  case "9":
                    return (
                      <>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order24}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            W
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("W")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "W" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order25}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            X
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("X")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "X" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order26}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            Y
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("Y")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "Y" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                        <div
                          className={`${style.contenidocentral__content_filtrado} ${style.ok_margin} ${style.order27}`}
                        >
                          <h3
                            className={style.contenidocentral__filtrado_letra}
                          >
                            Z
                          </h3>
                          <ul className={style.contenidocentral__filtrado_ul}>
                            {filterSpecials.some((e) =>
                              e.name[0].includes("Z")
                            ) ? (
                              filterSpecials
                                .sort((a, b) => {
                                  if (a.name > b.name) return 1;
                                  if (a.name < b.name) return -1;
                                  return 0;
                                })
                                .map(
                                  (r) =>
                                    r.name[0] === "Z" && (
                                      <Link
                                        key={r?.id}
                                        to={
                                          r.principal
                                            ? `/${area}/${
                                                r.name
                                              }/${convertToQuery(r?.id)}`
                                            : r.adults === "true"
                                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                                            : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                                        }
                                        className="link"
                                      >
                                        <div>
                                          <li>{r.name}</li>
                                        </div>
                                      </Link>
                                    )
                                )
                            ) : (
                              <div>
                                <li>Sin resultados</li>
                              </div>
                            )}
                          </ul>
                        </div>
                      </>
                    );
                }
              })()}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContenidoCentral;
