import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import style from "./ContentLeft.module.css";
import close from "./images/close.svg";
import { useSelector, useDispatch } from "react-redux";
import setSection from "../../actions/setSection";
import { useEffect } from "react";
import setContent from "../../actions/setContent";
import { sectionShow } from "../../actions";
import telemedicinaImg from "../../images/telemedicinaImg.svg";
import mapSites from "../../images/mapSites.svg";
import { Telemedicine } from "../Telemedicine/Telemedicine";
import { MyPortal } from "../MyPortal/MyPortal";

export const ContentLeft = ({
  path,
  sections,
  sites,
  rrhh,
  docSelection,
  preparations,
  type,
}) => {
  let [infoRrhh, setInfoRrhh] = useState(null);
  let [busquedasActivas, setBusquedasActivas] = useState(null);
  let [selected, setSelected] = useState("Información de RRHH");
  let dbQuery = sections && sections[0]?.tag;

  const [sectionParams, setSectionParams] = useState("");
  const { name, section } = useParams();
  const gralPaz = sites
    ? sites?.filter((site) => site.barrio === "General Paz")
    : null;
  const jardin = sites
    ? sites?.filter((site) => site.barrio === "Jardín")
    : null;
  const telemedicina = sites
    ? sites?.filter((site) => site.nombre === "Telemedicina")
    : null;

  const menuState = useSelector((state) => state.rootReducer.sectionShow);
  const dispatch = useDispatch();

  const filter =
    sections &&
    sections?.filter(
      (e) => e.name !== "default" && e.name !== "Nuestro equipo"
    );
    const order = filter && filter.sort((a, b) => {
      if (a.index === null || a.index === undefined) return 1; // Sitúa los null/undefined al final
      if (b.index === null || b.index === undefined) return -1;
      return b.index - a.index;
    });
    

  const handleSection = (value, content) => {
    dispatch(setSection(value));
    dispatch(setContent(content));
  };
  const handleClose = () => {
    dispatch(sectionShow(false));
  };

  const getInfoRrhh = () => {
    let arrayAux = rrhh.filter((rh) => rh.name === "Información de RRHH");
    setInfoRrhh(arrayAux[0]);
  };

  const getBusquedasActivas = () => {
    let arrayAux = rrhh.filter((rh) => {
      if (rh.name !== "Información de RRHH" && rh.name !== "default") {
        return rh;
      }
    });
    setBusquedasActivas(arrayAux);
  };

  useEffect(() => {
    setSectionParams(section);
    dispatch(sectionShow(false));
  }, [section]);

  useEffect(() => {
    if (rrhh) {
      getInfoRrhh();
      getBusquedasActivas();
    }
  }, [rrhh]);
  return (
    <div
      className={
        menuState
          ? `${style.containerGral}`
          : `${style.containerGral} ${style.false}`
      }
    >
      <div className={style.seccionesContainer}>
        <h3 className={style.title__column}>Secciones</h3>
        <img
          src={close}
          className={style.close}
          onClick={handleClose}
          alt="close"
        />
      </div>

      {/* -------------------------------------------------------------------------------------------
              SI EL ÁREA ES TELEMEDICIA O MI PORTAL
------------------------------------------------------------------------------------------- */}

      {sections && name === "telemedicina" ? (
        <Telemedicine sections={sections && sections} />
      ) : sections && name === "miPortal" ? (
        <MyPortal sections={sections && sections} />
      ) : (
        <>
          <div className={style.sectionContainer}>
            {order &&
              order?.map((section, i) => (
                section.content ? (
                  <Link
                    key={i}
                    style={{ textDecoration: "none" }}
                    to={
                      dbQuery
                        ? `/${path}/${name}/${section?.tag}`
                        : type
                        ? `/${path}/${name}/${section?.name}/${type}`
                        : `/${path}/${name}/${section?.name}`
                    }
                  >
                    <button
                      className={
                        sectionParams && sectionParams
                          ? (section?.tag || section?.name) === sectionParams
                            ? style.buttonSelect
                            : style.buttonSection
                          : style.buttonSection
                      }
                      onClick={() => handleSection(section.name, section.content)}
                      key={i}
                    >
                      {section.name}
                    </button>
                  </Link>
                ) : null
              ))}

            {/* ----------------------------------------------------------------------------------------------------------
                    FIN DE SECCIONES DE TELEMEDICINA O MI PORTAL
---------------------------------------------------------------------------------------------------------- */}

            {/* -------------------------------------------------------------------------------------------------------
                    SI EL ÁREA ES INSTITUCIONAL 
------------------------------------------------------------------------------------------------------- */}

            {name === "institucional" ? (
              <>
                <Link to="/general/equipo/Autoridades">
                  <button
                    className={
                      sectionParams
                        ? (section.tag || section.name) === sectionParams
                          ? style.buttonSelect
                          : style.buttonSection
                        : style.buttonSection
                    }
                  >
                    Nuestro equipo
                  </button>
                </Link>
                <Link to="/noticias/Novedades%20institucionales">
                  <button
                    className={
                      sectionParams
                        ? (section.tag || section.name) === sectionParams
                          ? style.buttonSelect
                          : style.buttonSection
                        : style.buttonSection
                    }
                  >
                    Novedades institucionales
                  </button>
                </Link>
              </>
            ) : null}

            {/* ------------------------------------------------------------------------------------------------------------
                    FIN SECCIONES INSTITUCIONAL
------------------------------------------------------------------------------------------------------------ */}

            {preparations && preparations?.length > 0 ? (
              <Link
                style={{ textDecoration: "none" }}
                to={
                  dbQuery
                    ? `/${path}/${name}/${section.tag}`
                    : type
                    ? `/${path}/${name}/Indicaciones y preparaciones/${type}`
                    : `/${path}/${name}/Indicaciones y preparaciones`
                }
              >
                <button
                  className={
                    sectionParams && sectionParams
                      ? (section?.tag ||
                          section?.name ||
                          "Indicaciones y preparaciones") === sectionParams
                        ? style.buttonSelect
                        : style.buttonSection
                      : style.buttonSection
                  }
                  onClick={() => handleSection("Indicaciones y preparaciones")}
                >
                  Indicaciones y preparaciones
                </button>
              </Link>
            ) : null}
          </div>
        </>
      )}
      {sites?.length > 0 ? (
        <div className={style.siteContainer}>
          <div className={style.spanContainer}>
            <span>¿Dónde encuentro el servicio?</span>
          </div>
          <article className={style.artContainer}>
            {gralPaz && gralPaz.length > 0 ? (
              <section className={style.neighborhoodContainer}>
                <div className={style.neighborhood}>
                  <img src={mapSites} alt="" className={style.img} />
                  <span>Barrio General Paz</span>
                </div>
                {gralPaz
                  ?.sort((a, b) =>
                    a.index > b.index ? 1 : b.index > a.index ? -1 : 0
                  )
                  .map((site, i) => (
                    <aside className={style.site} key={i}>
                      <span className={style.name}>{site.domicilio}</span>
                      {/* <span className={style.direccion}>{site.domicilio}</span> */}
                    </aside>
                  ))}
              </section>
            ) : null}
            {jardin.length > 0 ? (
              <section className={style.neighborhoodContainer}>
                <div className={style.neighborhood}>
                  <img src={mapSites} alt="" className={style.img} />
                  <span>Barrio Jardín Espinosa</span>
                </div>
                {jardin &&
                  jardin
                    ?.sort((a, b) =>
                      a.index > b.index ? 1 : b.index > a.index ? -1 : 0
                    )
                    .map((site, i) => (
                      <aside className={style.site} key={i}>
                        <span className={style.name}>{site.domicilio}</span>
                        {/* <span className={style.direccion}>
                          {site.domicilio}
                        </span> */}
                      </aside>
                    ))}
              </section>
            ) : null}
            {telemedicina.length > 0 && dbQuery === "especialidades" ? (
              <aside className={style.telemedicinaGral}>
                <div className={style.telemedicinaContainer}>
                  <img
                    src={telemedicinaImg}
                    alt=""
                    className={style.telemedicinaImg}
                  />
                  <span className={style.telemedicina}>
                    {telemedicina[0]?.nombre}
                  </span>
                </div>
                <div className={style.site}>
                  <span className={style.telemedicinaSpan}>
                    Esta especialidad tiene profesionales atendiendo por
                    telemedicina
                  </span>
                </div>
              </aside>
            ) : null}
            <div className={style.containerButton}>
              <button className={style.turno}>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href={"http://turnos.curf.com.ar"}
                >
                  Sacá tu turno online
                </a>
              </button>
            </div>
          </article>
        </div>
      ) : null}
      {rrhh ? (
        <div className={style.sectionContainer}>
          <ul className={style.container__sections}>
            <li
              className={selected === infoRrhh?.name ? style.selected : ""}
              onClick={() => {
                docSelection(infoRrhh);
                setSelected(infoRrhh?.name);
              }}
            >
              {infoRrhh?.name}
            </li>
          </ul>
        </div>
      ) : null}
      {rrhh ? (
        busquedasActivas ? (
          <div className={style.busquedasContainer}>
            <div className={style.container__titleBusqueda}>
              <span className={style.title__busquedas}>Búsquedas activas</span>
            </div>
            <ul className={style.container__sections}>
              {busquedasActivas.map((busqueda) => (
                <li
                  key={busqueda.name}
                  onClick={() => {
                    docSelection(busqueda);
                    setSelected(busqueda?.name);
                  }}
                  className={selected === busqueda?.name ? style.selected : ""}
                >
                  {busqueda.name}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className={style.notBusquedas_container}>
            <span>No hay busquedas activas</span>
          </div>
        )
      ) : null}
    </div>
  );
};
