import { useState, useEffect } from "react";
import style from "./GeneralAreas.module.css";
import Banner from "../Banner/Banner";
import ColumnaIzq from "../ColumnaIzq/ColumnaIzq";
import ContenidoCentral from "../ContenidoCentral/ContenidoCentral";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ContentLeft } from "../ContentLeft/ContentLeft";
import { ContentRight } from "../ContentRight/ContentRight";
import flecha from "./felcha izquierda.svg";
import setContent from "../../actions/setContent";
import iconoSug from "./icono de sugerencias.svg";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useData } from "../../hooks/api";

export const GeneralAreas = () => {
  const [current, setCurrent] = useState([]);
  const { name, guia, section } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasNavigated, setHasNavigated] = useState(false);

  // Firebase Analytics
  const analytics = getAnalytics();
  logEvent(analytics, "screen_view", {
    firebase_screen: `Área general: ${name}`,
    firebase_screen_class: "Áreas generales",
  });

  // Obtener datos
  const { data: fetchData, isLoading: fetchLoading, error: fetchError } = useData(name);

  const getArea = async () => {
    try {
      if (name === "guiaPaciente" && guia) {
        console.log("Fetching data for Guía Paciente");
      } else {
        if (fetchError) {
          console.error(`Error fetching data for ${name}:`, fetchError);
          return;
        }
        if (!fetchLoading) {
          setCurrent(fetchData);
        }
      }
    } catch (e) {
      console.error("No hay resultados disponibles:", e);
    }
  };

  useEffect(() => {
    getArea();
  }, [name, guia, fetchData, fetchLoading, fetchError]);

  // Definición de sections y path
  const sections = current && current.filter((e) => e.name !== "default");
  const path = (current && (current.kids || current.adults)) ? "especialidades" : "general";

  // Resetea hasNavigated cuando name cambia
  useEffect(() => {
    setHasNavigated(false);
  }, [name]);
  useEffect(() => {
    if (sections && sections.length > 0) {
      // Encuentra la primera sección con contenido válido
      const firstSectionWithContent = sections.find(sec => sec.content && sec.content.trim() !== "");
      
      if (firstSectionWithContent) {
        const firstSectionTag = firstSectionWithContent.tag;
  
        // Verifica si la sección actual existe en las secciones
        const sectionExists = sections.some((sec) => sec.tag === section);
  
        if (!sectionExists) {
          setHasNavigated(true);
          navigate(`/${path}/${name}/${firstSectionTag}`);
        } else if (!section && !hasNavigated) {
          setHasNavigated(true);
          navigate(`/${path}/${name}/${firstSectionTag}`);
        }
      }
    }
  }, [section, sections, hasNavigated, path, name, navigate]);

  useEffect(() => {
    if (section) {
      filter();
    }
  }, [current, section]);

  const filter = () => {
    const filterSec = sections ? sections.filter((e) => e.tag === `${section}`) : null;
    dispatch(setContent(filterSec && filterSec[0]?.content));
  };

  // Filter default items
  const defaultItem = current && current.filter((e) => e.name === "default");
  // Map section names and tags
  const namesTitle = sections && sections.map((e) => ({ name: e.name, tag: e.tag }));
  // Map section content
  const contentSections = sections && sections.map((e) => e.content);

  
  return (
    <div>
      <div className={style.container__main}>
        <Banner
          // Ensure defaultItem is defined and not empty before accessing index 0
          image={defaultItem && defaultItem.length > 0 ? defaultItem[0].img : undefined}
          title={defaultItem && defaultItem.length > 0 ? defaultItem[0].title : undefined}
          iconProp={name === "sugerencias" && iconoSug}
        />
        <div className={style.container__flecha_home}>
          <div
            className={style.container__flecha_home_content}
            onClick={() => navigate(-1)}
          >
            <img src={flecha} alt="Flecha" className={style.flecha__home} />
            <span>Volver</span>
          </div>
        </div>
      </div>
      <div className={style.container__content}>
        {name ? (
          <ContentLeft path={path} sections={sections} />
        ) : (
          <ColumnaIzq />
        )}
        {name ? (
          <ContentRight
            path={path}
            contentSections={contentSections}
            titleName={namesTitle}
            sugerencias={name === "sugerencias" ? current : null}
          />
        ) : (
          <ContenidoCentral />
        )}
      </div>
    </div>
  );
};
