import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./Backoffice-Specialties.css";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import { BackofficeNav } from "../../Backoffice-Components/Backoffice-Nav/Backoffice-Nav";
import { BackofficeSidebar } from "../../Backoffice-Components/Backoffice-Sidebar/Backoffice-Sidebar";
import { BackofficeModal } from "../../Backoffice-Components/Backoffice-Modal/Backoffice-Modal";
import openModal from "../../../../actions/backoffice-actions/openModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ImageOutlined } from "@mui/icons-material";
import { useData, useDeleteData, useEditData } from "../../../../hooks/api";

export const BackofficeSpecialties = () => {
  const [currentSpecialties, setCurrentSpecialties] = useState([]);
  const [modal, setModal] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [save, setSave] = useState(false);
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const { area } = useParams();

  const information =
    currentSpecialties &&
    currentSpecialties.filter((e) => e.name === "Información general");

  const question =
    currentSpecialties &&
    currentSpecialties.filter(
      (e) => e.name === "¿Qué son las unidades asistenciales?"
    );

  let dbQuery = area;

  if (dbQuery === "especialidades") {
    dbQuery = "specialtiesList";
  } else {
    dbQuery = area;
  }

  const modalImage = useSelector((state) => state.backofficeReducer.setModal);

  const { data: specialtiesDb } = useData(dbQuery);
  const deleteDataMutation = useDeleteData(dbQuery);
  const editDataMutation = useEditData(dbQuery);

  const deleteSpecialty = async (id) => {
    try {
      await deleteDataMutation.mutateAsync(id);
    } catch (e) {
      console.error("El usuario no ha podido ser eliminado");
    }
  };

  const editSections = async () => {
    const content = editorRef.current.getContent();
    if (dbQuery === "laboratorios") {
      editDataMutation.mutate({
        ...information[0],
        content: content,
      });
    } else {
      editDataMutation.mutate({
        ...question[0],
        content: content,
      });
    }
  };

  const handleConfirm = () => {
    if (editorRef.current) {
      setDirty(false);
      setSave(true);
      editorRef.current.setDirty(false);
      // an application would save the editor content to the server here
    }
  };

  const handleConfirmMarkdown = () => {
    editSections();
    setModal(false);
    setSave(false);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setModal(false);
  };

  const handleModal = () => {
    dispatch(openModal());
  };

  useEffect(() => setDirty(false), []);

  useEffect(() => {
    if (specialtiesDb) {
      const filter = specialtiesDb.filter((e) => e.name !== "default");
      setCurrentSpecialties(filter);
    }
  }, [specialtiesDb]);

  const rows = currentSpecialties.map((u) => {
    if (dbQuery === "specialtiesList") {
      return {
        id: u.id,
        name: u.name,
        adults: u.adults,
        kids: u.kids,
        sections: u.sections,
        sites: u.sites,
        img: u.img,
      };
    } else {
      return {
        id: u.id,
        name: u.name,
        adults: u.adults,
        principal: u.principal,
        sections: u.sections,
        sites: u.sites,
        img: u.img,
      };
    }
  });

  const columns = [
    {
      field: "name",
      headerName:
        dbQuery === "specialtiesList"
          ? "Especialidad"
          : dbQuery === "laboratorios"
          ? "Laboratorio"
          : "Unidad",
      width: 400,
      alignHeader: "center",
    },
    {
      field: "adults",
      headerName: dbQuery === "specialtiesList" ? "Tipo" : "Principal",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {dbQuery === "specialtiesList" ? (
              params.row.name !== "Diagnóstico por imágenes" ? (
                <div className="check">
                  {params.row.adults === "true" ? (
                    <span>{"Adulto"}</span>
                  ) : (
                    <span>{"Pediátrico"}</span>
                  )}
                </div>
              ) : null
            ) : null}
            {dbQuery === "unidadesAsistenciales" ? (
              <div className="checkContainer">
                {params.row.principal !== "true" ? (
                  <CancelIcon style={{ color: "red" }} />
                ) : (
                  <CheckCircleIcon style={{ color: "teal" }} />
                )}
              </div>
            ) : null}
          </>
        );
      },
    },
    {
      field: "",
      headerName: "Editar/Borrar",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            className={
              params.row.name !== "Información general" &&
              params.row.name !== "¿Qué son las unidades asistenciales?"
                ? "delete-edit-button"
                : "edit-button"
            }
          >
            <Link
              style={{ textDecoration: "none" }}
              onClick={
                dbQuery === "laboratorios"
                  ? params.row.name === "Información general"
                    ? () => setModal(true)
                    : null
                  : params.row.name === "¿Qué son las unidades asistenciales?"
                  ? () => setModal(true)
                  : null
              }
              to={
                params.row.name !== "Información general" &&
                params.row.name !== "¿Qué son las unidades asistenciales?"
                  ? `/backoffice/${area}/${params.row.id}`
                  : ""
              }
            >
              <button className="userListEdit">Edit</button>
            </Link>
            {params.row.name !== "Información general" &&
            params.row.name !== "¿Qué son las unidades asistenciales?" ? (
              <DeleteOutlinedIcon
                className="userListDelete"
                onClick={() =>
                  window.confirm("Está seguro que desea eliminar este item?")
                    ? deleteSpecialty(params.row.id)
                    : null
                }
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <div className="backofficeUserList">
      <div className="backofficeNav">
        <BackofficeNav />
      </div>
      <div className="sidebar-userlist">
        <div className="sidebar">
          <BackofficeSidebar />
        </div>
        <div className="userList">
          <div className="link-create">
            <button
              onClick={() => navigate(`/backoffice/${area}/crear`)}
              className="userAddButton"
            >
              Crear
            </button>
          </div>
          <div className="datagridContainer">
            <DataGrid
              rows={rows}
              disableSelectionOnClick
              columns={columns}
              pageSize={10}
              checkboxSelection
            />
          </div>
        </div>
      </div>
      {modal ? (
        <article className="modal">
          <section className="modalContainer">
            <span>{information[0]?.name}</span>
            <div>
              <button className="open-modal" onClick={() => handleModal()}>
                <ImageOutlined width="40" height="40" />
              </button>
            </div>
            <div
              className={
                modalImage ? "modalImageContainer" : "modalImageContainerOff"
              }
            >
              <BackofficeModal />
            </div>
            <form className="form-markdown">
              <Editor
                apiKey="slz93nsspb367zfebawu3ptagqrlbuyxfora657iog3xugbm"
                textareaName="description"
                initialValue={
                  dbQuery === "laboratorios"
                    ? information[0]?.content
                    : question[0]?.content
                }
                onInit={(evt, editor) => (editorRef.current = editor)}
                onDirty={() => setDirty(true)}
                init={{
                  selector: "textarea.tinymce",
                  theme: "silver",
                  height: 400,
                  onchange_callback: "handleChange",
                  plugins: [
                    // FORMATO DEL MENU
                    "advlist autolink link image lists charmap preview hr anchor pagebreak",
                    "searchreplace wordcount visualblocks code visualchars nonbreaking",
                    "save table contextmenu directionality paste textcolor ",
                  ],
                  toolbar:
                    "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media| print preview fullpage | forecolor backcolor",
                  image_advtab: true,
                  deprecation_warnings: false,
                }}
              />
            </form>
          </section>
          <div className="containerButtonGroup">
            <button className="markdown-button" onClick={(e) => handleClose(e)}>
              Cancelar
            </button>
            <button
              className="modified-button"
              onClick={() => handleConfirm()}
              disabled={!dirty}
            >
              Modificar
            </button>
            <button
              className={save ? "saveButton" : "saveDisabled"}
              disabled={save ? false : true}
              onClick={() => handleConfirmMarkdown()}
            >
              Guardar
            </button>
          </div>
        </article>
      ) : null}
    </div>
  );
};
