import { useState } from "react";
import { GaleriaSlider, Slide } from "../GaleriaSlider/GaleriaSlider";
import closeBtn from "./close-line.svg";
import style from "./Galeria.module.css";

const Galeria = ({ images }) => {
  let [imageSelect, setImageSelect] = useState();
  let [showPopUp, setShowPopUp] = useState(false);

  const imageController = (event) => {
    setImageSelect(event.target.src);
    setShowPopUp(true);
  };

  return (
    <div className={style.container__main}>
      <div className={style.container__content}>
        <div className={style.container__title}>
          <h3>Galería</h3>
        </div>
        <div className={style.container__images}>
          <GaleriaSlider>
            {images.map((image, index) => (
              <Slide key={index}>
                <img
                  src={image}
                  alt="imagen Slide"
                  className={style.imagenSlide}
                  onClick={imageController}
                />
              </Slide>
            ))}
          </GaleriaSlider>
        </div>
      </div>

      {showPopUp ? (
        <div className={style.overlay}>
          <div className={style.overlay_container__image}>
            <img
              src={closeBtn}
              alt="closeBtn"
              className={style.closeBtn}
              onClick={() => setShowPopUp(false)}
            />
            <img src={imageSelect} alt="imagenPrueba1" />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Galeria;
