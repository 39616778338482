import { useState, useEffect, useRef } from "react";
import style from "./Backoffice-Edit-GeneralAreas.module.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Editor } from "@tinymce/tinymce-react";
import { v4 as uuidv4 } from "uuid";
import openModal from "../../../../actions/backoffice-actions/openModal";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { BackofficeModal } from "../Backoffice-Modal/Backoffice-Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { ImageOutlined } from "@mui/icons-material";
import { useData, useDeleteData, useConfirmEmail, useEditData, useAddData } from "../../../../hooks/api";

export const BackofficeEditGeneralAreas = () => {
  const [rows, setRows] = useState("");
  const [modal, setModal] = useState(false);
  const [modalSection, setModalSection] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [save, setSave] = useState(false);
  const [editArea, setEditArea] = useState("");
  const [index, setIndex] = useState("");
  const [input, setInput] = useState({
    name: "",
    tag: "",
    content: "",
    id: uuidv4(),
    index: "",
  });

  const { area } = useParams();
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const backofficeReducer = useSelector((state) => state.backofficeReducer);
  const modalImage = backofficeReducer.setModal;

  const { data: areaData } = useData(area);
  const { mutate: deleteSectionMutate } = useDeleteData(area);
  const { mutate: addSectionMutate } = useAddData(area);
  const { mutate: editSectionMutate } = useEditData(area);

  const handleDeleteSection = async (id) => {
    deleteSectionMutate(id);
  };

  const handleModal = () => {
    dispatch(openModal());
  };

  const handleEdit = (value) => {
    setModal(true);
    setEditArea(value);
  };

  const handleConfirm = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      setSave(true);
      editorRef.current.setDirty(false);
      const filtered = areaData?.filter((e) => e.name === editArea.name);
      setEditArea({ ...filtered[0], content: content });
      setSave(true);
    }
  };

  const handleSubmitEditSection = () => {
    setSave(false);
    editSectionMutate(editArea, {
      onSuccess: () => {
        setEditArea("");
        setModal(false);
      }
    });
  };

  const handleModalSection = (e) => {
    e.preventDefault();
    setModalSection(true);
  };

  const handleSubmitNewSection = (e) => {
    e.preventDefault();
    addSectionMutate(input, {
      onSuccess: () => {
        setModalSection(false);
        setInput({
          name: "",
          tag: "",
          content: "",
          id: uuidv4(),
          index: "",
        });
        window.location.reload();
      },
    });
  };

  const handleClose = (e) => {
    e.preventDefault();
    setModal(false);
  };

  const handleChange = (evt, elem) => {
    if (evt?.target?.name === "email") {
      handleConfirmEmail({ elem, value: evt?.target?.value });
    } else {
      setInput({
        ...input,
        name: evt?.target?.value,
        tag: evt?.target?.value,
        index: index + 1,
      });
    }
  };

  const { mutate: confirmEmailMutate } = useConfirmEmail();

  const handleConfirmEmail = (obj) => {
    confirmEmailMutate(obj);
  };

  const columns = [
    {
      field: "name",
      headerName: "Área",
      width: 350,
      alignHeader: "center",
    },
    {
      field: "type",
      headerName: "Tipo",
      width: 200,
    },
    {
      field: "",
      headerName: "Editar/Borrar",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="delete-edit-button">
            <button
              onClick={() =>
                handleEdit({
                  name: params.row.name,
                  id: params.row.id,
                  tag: params.row.tag,
                  content: params.row.content,
                })
              }
              className="userListEdit"
            >
              Edit
            </button>
            {params.row.tag !== "Autoridades" ? (
              <DeleteOutlinedIcon
                className="userListDelete"
                onClick={() =>
                  window.confirm("Está seguro que desea eliminar este item?")
                    ? handleDeleteSection(params.row.id)
                    : null
                }
              />
            ) : null}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email vinculado",
      width: 300,
      alignHeader: "center",
      renderCell: (elem) => {
        return elem.row.tag ? (
          <select
            defaultValue={elem.row.email}
            onChange={(evt) => handleChange(evt, elem.row)}
            name="email"
          >
            <option value="">---</option>
            <option value="postulación@curf.ucc.edu.ar">
              postulación@curf.ucc.edu.ar
            </option>
            <option value="postulacionenfermeria@curf.ucc.edu.ar ">
              postulacionenfermeria@curf.ucc.edu.ar
            </option>
          </select>
        ) : null;
      },
    },
  ];

  useEffect(() => {
    if (areaData) {
      let order = areaData
        .filter((e) => e.name !== "default")
        .sort((a, b) => (a.index > b.index ? -1 : b.index > a.index ? 1 : 0));
      let num = order[0]?.index;
      setIndex(num + 1);
    }
  }, [areaData]);

  useEffect(() => {
    if (areaData) {
      const dataEntry = areaData.filter((data) => data.name !== "default");

      if (area === "telemedicina") {
        setRows(
          dataEntry
            .filter(
              (e) =>
                e.name !== "Gestión de turnos" && e.name !== "Sala de espera"
            )
            .map((u) => {
              return {
                id: u.id,
                name: u.name,
                tag: u.tag,
                content: u.content,
              };
            })
        );
      } else if (area === "miPortal") {
        setRows(
          dataEntry
            .filter((e) => e.name === "Información general")
            .map((u) => {
              return {
                id: u.id,
                name: u.name,
                tag: u.tag,
                content: u.content,
              };
            })
        );
      } else {
        setRows(
          dataEntry.map((u) => {
            return {
              id: u.id,
              name: u.name,
              tag: u.tag,
              content: u.content,
              email: u?.email,
            };
          })
        );
      }
    }
  }, [areaData, area]);

  return (
    <div className={style.container}>
      {area === "institucional" ||
      area === "circuitosMedicos" ||
      area === "telemedicina" ||
      area === "miPortal" ? null : (
        <form
          onSubmit={handleSubmitNewSection}
          className={style.newSection}
        >
          <button
            onClick={handleModalSection}
            className={style.sectionButton}
          >
            Nueva sección
          </button>
          {modalSection ? (
            <>
              <input
                name="name"
                onChange={handleChange}
                type="text"
              />
              <button type="submit" className={style.sectionButtonSave}>
                Crear
              </button>
              <CancelIcon
                onClick={() => setModalSection(false)}
                className={style.cancelIcon}
              />
            </>
          ) : null}
        </form>
      )}
      <div className="datagridContainer">
        <DataGrid
          rows={rows}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
        />
      </div>
      {modal ? (
        <article className={style.modal}>
          <section className={style.modalContainer}>
            <span>{editArea?.name}</span>
            <div>
              <button className="open-modal" onClick={handleModal}>
                <ImageOutlined width="40" height="40" />
              </button>
            </div>
            <div
              className={
                modalImage ? "modalImageContainer" : "modalImageContainerOff"
              }
            >
              <BackofficeModal />
            </div>
            <form className={style.formMarkdown}>
              <Editor
                apiKey="slz93nsspb367zfebawu3ptagqrlbuyxfora657iog3xugbm"
                textareaName="description"
                initialValue={editArea && editArea?.content}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onDirty={() => setDirty(true)}
                init={{
                  deprecation_warnings: false,
                  selector: "textarea.tinymce",
                  theme: "silver",
                  height: 400,
                  plugins: [
                    "advlist autolink link image lists charmap preview hr anchor pagebreak",
                    "searchreplace wordcount visualblocks code visualchars nonbreaking",
                    "save table contextmenu directionality paste textcolor ",
                  ],
                  toolbar:
                    "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media| print preview fullpage | forecolor backcolor",
                  image_advtab: true,
                }}
              />
            </form>
          </section>
          <div className={style.containerButtonGroup}>
            <button className="markdown-button" onClick={handleClose}>
              Cancelar
            </button>
            <button
              className="modified-button"
              onClick={handleConfirm}
              disabled={!dirty}
            >
              Modificar
            </button>
            <button
              className={save ? "saveButton" : "saveDisabled"}
              disabled={save ? false : true}
              onClick={handleSubmitEditSection} // Cambiado a handleSubmitEditSection
            >
              Guardar
            </button>
          </div>
        </article>
      ) : null}
    </div>
  );
};
